<template>
  <div
    class="modal fade"
    id="scriptHelperModal"
    tabindex="-1"
    aria-labelledby="scriptHelperModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`scriptHelperModalLabel`">
            <i class="bi bi-info-circle"></i>
            {{
              $t(
                "Components.ScriptHelperModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close btn-translation-modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="accordion" id="setScriptHelperAccordion">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#one"
                  aria-expanded="true"
                >
                  {{
                    $t(
                      "Components.ScriptHelperModal.SXClass",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </h2>
              <div
                id="one"
                class="accordion-collapse collapse show"
                data-bs-parent="#setScriptHelperAccordion"
              >
                <div class="accordion-body">
                  <div
                    class="accordion"
                    id="setScriptHelperCrudOrFormElementsAccordion"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#crud"
                          aria-expanded="false"
                        >
                          {{
                            $t(
                              "Components.ScriptHelperModal.CRUD",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </button>
                      </h2>
                      <div
                        id="crud"
                        class="accordion-collapse collapse"
                        data-bs-parent="#setScriptHelperCrudOrFormElementsAccordion"
                      >
                        <div
                          class="d-flex align-items-center justify-content-center mt-2 mb-2"
                        >
                          <div class="col-md-9 col-sm-12 col-xs-12">
                            <div class="alert alert-dark mb-0">
                              <p class="mb-0">
                                <b class="text-primary">SX</b>.<b
                                  class="text-warning"
                                  >getRecordsCount</b
                                >({ <br />&nbsp;&nbsp;CustomObjectKey:
                                'satinalma-urun',
                                <br />&nbsp;&nbsp;ViewFilterFormulaName:
                                'SATINALMA_TALEPLERIM'<br />
                                }).then((result) => {<br />
                                &nbsp; <b class="text-primary">if</b> (<b
                                  class="text-success"
                                  >result.IsOk</b
                                >) {<br />
                                &nbsp;&nbsp;&nbsp;$("#box").text(result.Data);<br />
                                &nbsp;} <b class="text-primary">else</b> {<br />
                                &nbsp;&nbsp;&nbsp;$("#box").text(result.ErrorMessage|result.CustomMessage);<br />
                                &nbsp;}<br />
                                }).catch((<b class="text-danger">error</b>) =>
                                {<br />
                                &nbsp;&nbsp;&nbsp;$("#box").text(error.Message);<br />});<br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="table-responsive">
                          <table
                            class="table table-primary table-layout-fixed mb-0 table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th width="250">
                                  {{
                                    $t(
                                      "BaseModelFields.Name",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                                <th width="250">
                                  {{
                                    $t(
                                      "BaseModelFields.Description",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                                <th>
                                  {{
                                    $t(
                                      "Components.ScriptHelperModal.Example",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                                <th>
                                  {{
                                    $t(
                                      "Components.ScriptHelperModal.Parameters",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tr
                              v-for="(sxFunction, i) in sxCrudFunctions"
                              :key="i"
                              class="bg-dark"
                            >
                              <td class="text-warning">{{ sxFunction.id }}</td>
                              <td class="text-white">
                                {{ sxFunction.description }}
                              </td>
                              <td class="text-white">
                                <code>
                                  {{ sxFunction.example }}
                                </code>
                              </td>
                              <td>
                                <div
                                  class="table-responsive bg-dark"
                                  v-if="sxFunction.parameters.length > 0"
                                >
                                  <table class="table table-warning p-0 mb-0">
                                    <thead>
                                      <tr>
                                        <th>
                                          {{
                                            $t(
                                              "BaseModelFields.Name",
                                              {},
                                              {
                                                locale:
                                                  this.$store.state.activeLang,
                                              }
                                            )
                                          }}
                                        </th>
                                        <th>
                                          {{
                                            $t(
                                              "Components.ScriptHelperModal.Type",
                                              {},
                                              {
                                                locale:
                                                  this.$store.state.activeLang,
                                              }
                                            )
                                          }}
                                        </th>
                                        <th>
                                          {{
                                            $t(
                                              "Components.ScriptHelperModal.Required",
                                              {},
                                              {
                                                locale:
                                                  this.$store.state.activeLang,
                                              }
                                            )
                                          }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          functionParameter, y
                                        ) in sxFunction.parameters"
                                        :key="y"
                                        class="bg-warning text-dark"
                                      >
                                        <td>
                                          {{ functionParameter.name }}
                                        </td>
                                        <td>
                                          {{ functionParameter.type }}
                                        </td>
                                        <td class="text-center">
                                          <i
                                            class="bi bi-check text-success fs-3"
                                            v-if="functionParameter.required"
                                          ></i>
                                          <i
                                            class="bi bi-x text-danger fs-3"
                                            v-else
                                          ></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#formElements"
                          aria-expanded="false"
                        >
                          {{
                            $t(
                              "Components.ScriptHelperModal.FormElements",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </button>
                      </h2>
                      <div
                        id="formElements"
                        class="accordion-collapse collapse"
                        data-bs-parent="#setScriptHelperCrudOrFormElementsAccordion"
                      >
                        <div class="table-responsive">
                          <table
                            class="table table-primary table-layout-fixed mb-0 table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th width="250">
                                  {{
                                    $t(
                                      "BaseModelFields.Name",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                                <th width="250">
                                  {{
                                    $t(
                                      "BaseModelFields.Description",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                                <th>
                                  {{
                                    $t(
                                      "Components.ScriptHelperModal.Example",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                                <th>
                                  {{
                                    $t(
                                      "Components.ScriptHelperModal.Parameters",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tr
                              v-for="(sxFunction, i) in sxFormElementFunctions"
                              :key="i"
                              class="bg-dark"
                            >
                              <td class="text-warning">{{ sxFunction.id }}</td>
                              <td class="text-white">
                                {{ sxFunction.description }}
                              </td>
                              <td class="text-white">
                                <code>
                                  {{ sxFunction.example }}
                                </code>
                              </td>
                              <td>
                                <div
                                  class="table-responsive bg-dark"
                                  v-if="sxFunction.parameters.length > 0"
                                >
                                  <table class="table table-warning p-0 mb-0">
                                    <thead>
                                      <tr>
                                        <th>
                                          {{
                                            $t(
                                              "BaseModelFields.Name",
                                              {},
                                              {
                                                locale:
                                                  this.$store.state.activeLang,
                                              }
                                            )
                                          }}
                                        </th>
                                        <th>
                                          {{
                                            $t(
                                              "Components.ScriptHelperModal.Type",
                                              {},
                                              {
                                                locale:
                                                  this.$store.state.activeLang,
                                              }
                                            )
                                          }}
                                        </th>
                                        <th>
                                          {{
                                            $t(
                                              "Components.ScriptHelperModal.Required",
                                              {},
                                              {
                                                locale:
                                                  this.$store.state.activeLang,
                                              }
                                            )
                                          }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          functionParameter, y
                                        ) in sxFunction.parameters"
                                        :key="y"
                                        class="bg-warning text-dark"
                                      >
                                        <td>
                                          {{ functionParameter.name }}
                                        </td>
                                        <td>
                                          {{ functionParameter.type }}
                                        </td>
                                        <td class="text-center">
                                          <i
                                            class="bi bi-check text-success fs-3"
                                            v-if="functionParameter.required"
                                          ></i>
                                          <i
                                            class="bi bi-x text-danger fs-3"
                                            v-else
                                          ></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#two"
                  aria-expanded="false"
                >
                  {{
                    $t(
                      "Components.ScriptHelperModal.EventListenerParameters",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </h2>
              <div
                id="two"
                class="accordion-collapse collapse"
                data-bs-parent="#setScriptHelperAccordion"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="alert alert-dark">
                        <p class="mb-0">
                          <b class="text-primary">function</b>
                          <b class="text-warning ms-1">crud_handle(e)</b>
                          <b class="text-danger ms-1">{</b><br />
                          <b class="text-primary"> var</b> eventDetail =
                          e.detail, <br />result = eventDetail.result,<br />
                          model = eventDetail.model;<br /><br />
                          console.log(result, model);<br />
                          <b class="text-danger">}</b><br />
                          <code class="fw-bold text-white">
                            SX.addEventListener("SX_NEW_RECORD_CREATED",
                            <b class="text-warning">crud_handle</b>);</code
                          >
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="alert alert-dark">
                        <p class="mb-0">
                          <b class="text-primary">function</b>
                          <b class="text-warning ms-1">grid_handle(e)</b>
                          <b class="text-danger ms-1">{</b><br />
                          <b class="text-primary"> var</b> eventDetail =
                          e.detail, <br />gridDetail = eventDetail.grid;<br /><br />
                          <b class="text-primary">if</b>
                          (gridDetail.data.filter.publicId === "X") { } <br />
                          <b class="text-primary">else if</b>
                          (gridDetail.data.filter.publicId === "Y") { } <br /><b
                            class="text-danger"
                            >}</b
                          ><br />
                          <code class="fw-bold text-white">
                            SX.addEventListener("SXGRID_CONTENT_READY",
                            <b class="text-warning">grid_handle</b>);</code
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <table class="table table-primary table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>
                          {{
                            $t(
                              "BaseModelFields.Name",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </th>
                        <th>
                          {{
                            $t(
                              "BaseModelFields.Description",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tr v-for="(event, i) in eventListeners" :key="i">
                      <td>
                        {{ event.name }}
                      </td>
                      <td>
                        {{
                          $t(
                            "Components.ScriptHelperModal." + event.name,
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-4">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="bi bi-x"></i>
            {{
              $t("Buttons.Close", {}, { locale: this.$store.state.activeLang })
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ScriptHelperModal",
  data() {
    return {
      eventListeners: [
        {
          name: "SX_NEW_RECORD_CREATED",
          description: "",
        },
        {
          name: "SX_EDIT_RECORD_UPDATED",
          description: "",
        },
        {
          name: "SXGRID_ON_PAGING",
          description: "",
        },
        {
          name: "SXGRID_ON_SORTING",
          description: "",
        },
        {
          name: "SXGRID_ON_REFRESH",
          description: "",
        },
        {
          name: "SXGRID_ON_SEARCH",
          description: "",
        },
        {
          name: "SXGRID_GETDATA_FINALLY",
          description: "",
        },
        {
          name: "SXGRID_GETDATA_REQUEST_UNSUCCESSFUL",
          description: "",
        },
        {
          name: "SXGRID_CONTENT_READY",
          description: "",
        },
        {
          name: "SXGRID_INITIALIZED",
          description: "",
        },
        {
          name: "SXGRID_INIT_NEW_ROW",
          description: "",
        },

        {
          name: "SXGRID_ON_SAVE",
          description: "",
        },
        {
          name: "SXGRID_EDITING_CHANGES",
          description: "",
        },
      ],
      sxCrudFunctions: [
        {
          id: "getBlobImageUrl",
          type: "async",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "documentPublicId",
              type: "Guid",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.GetBlobImageUrlDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.getBlobImageUrl("GUID").then((r) => {}) `,
        },
        {
          id: "deleteRecord",
          type: "async",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "customObjectKey",
              type: "String",
              required: true,
            },
            {
              name: "recordPublicId",
              type: "Guid",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.DeleteRecordDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example:
            "SX.deleteRecord('musteri', '798d8a29-xxxx-xxx-xxxx-77733ac6cabd').then((r) => {}).catch((c) => {});",
        },
        {
          id: "getRecords",
          type: "async",
          parameterType: "Object Model Parameters",
          parameters: [
            {
              name: "CustomObjectKey",
              type: "String",
              required: true,
            },
            {
              name: "ViewFilterFormulaName",
              type: "String",
              required: true,
            },
            {
              name: "Query",
              type: "String",
              required: false,
            },
            {
              name: "PageSize",
              type: "Number",
              required: false,
            },
            {
              name: "PageNumber",
              type: "Number",
              required: false,
            },
            {
              name: "IsAdhoc",
              type: "Boolean",
              required: false,
            },
            {
              name: "Criterias",
              type: "List<Criteria>",
              required: false,
            },
            {
              name: "CriteriaExpression",
              type: "String",
              required: false,
            },
            {
              name: "FieldFormulaNames",
              type: "List<String>",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.GetRecordsDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example:
            "SX.getRecords({CustomObjectKey: 'firma', ViewFilterFormulaName: '', PageSize: 50, PageNumber:1 }).then((r) => {}).catch((c) => {});",
        },
        {
          id: "getRecordsCount",
          type: "async",
          parameterType: "Object Model Parameters",
          parameters: [
            {
              name: "CustomObjectKey",
              type: "String",
              required: true,
            },
            {
              name: "ViewFilterFormulaName",
              type: "String",
              required: true,
            },
            {
              name: "Query",
              type: "String",
              required: false,
            },
            {
              name: "PageSize",
              type: "Number",
              required: false,
            },
            {
              name: "PageNumber",
              type: "Number",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.GetRecordsCountDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example:
            "SX.getRecordsCount({CustomObjectKey: 'firma', ViewFilterFormulaName: '' }).then((r) => {console.log(r.count)}).catch((c) => {});",
        },
        {
          id: "getRecord",
          type: "async",
          parameterType: "Object Model Parameters",
          parameters: [
            {
              name: "PublicId",
              type: "Guid",
              required: true,
            },
            {
              name: "CustomObjectKey",
              type: "String",
              required: true,
            },
            {
              name: "PageLayoutFormulaName",
              type: "String",
              required: false,
            },
            {
              name: "FieldFormulaNames",
              type: "List<String>",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.GetRecordDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example:
            "SX.getRecord({CustomObjectKey: 'firma', PublicId: 'RECORD_PUBLIC_ID' }).then((r) => {}).catch((c) => {});",
        },
        {
          id: "getRelationalRecords",
          type: "async",
          parameterType: "Object Model Parameters",
          parameters: [
            {
              name: "ParentRecordPublicId",
              type: "Guid",
              required: true,
            },
            {
              name: "LookupRelationFormulaName",
              type: "String",
              required: true,
            },
            {
              name: "ViewFilterName",
              type: "String",
              required: false,
            },
            {
              name: "Query",
              type: "String",
              required: false,
            },
            {
              name: "PageSize",
              type: "Number",
              required: false,
            },
            {
              name: "PageNumber",
              type: "Number",
              required: false,
            },
            {
              name: "FieldFormulaNames",
              type: "List<String>",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.GetRelationalRecordsDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example:
            "SX.getRelationalRecords(LookupRelationFormulaName: 'X', ViewFilterName: '' }).then((r) => {}).catch((c) => {});",
        },
        {
          id: "createRecord",
          type: "async",
          parameterType: "Object Model Parameters",
          parameters: [
            {
              name: "CustomObjectKey",
              type: "Guid",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.CreateRecordDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.createRecord(
        {
            CustomObjectKey: 'firma',
            "PageLayoutFormulaName":"",
            "FieldsValues":[
            {
                "Key":"BAGLI_OLD_POS_GIRISI",
                "Value":"6ddde301-xxxx-xxxx-xxxx-e9a7ddf45a69"
            },
            {
                "Key":"ISLEM_NO",
                "Value":"212912300"
            }] 
        }).then((r) => {}).catch((c) => {});`,
        },
        {
          id: "updateRecord",
          type: "async",
          parameterType: "Object Model Parameters",
          parameters: [
            {
              name: "CustomObjectKey",
              type: "Guid",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.UpdateRecordDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.updateRecord(
        {
            CustomObjectKey: 'firma',
            "PageLayoutFormulaName":"",
            "RecordPublicId:"x",
            "FieldsValues":[
            {
                "Key":"BAGLI_OLD_POS_GIRISI",
                "Value":"6ddde301-xxxx-xxxx-xxxx-e9a7ddf45a69"
            },
            {
                "Key":"ISLEM_NO",
                "Value":"212912300"
            }] 
        }).then((r) => {}).catch((c) => {});`,
        },
      ],
      sxFormElementFunctions: [
        {
          id: "createNotification",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "type",
              type: "String (success|warning|danger|dark|primary|info)",
              required: true,
            },
            {
              name: "message",
              type: "String",
              required: true,
            },
            {
              name: "position",
              type: "String (top-left|top-right*|bottom-left|bottom-right|top-center|bottom-center)",
              required: false,
            },
            {
              name: "showIcon",
              type: "Boolean (true*|false)",
              required: false,
            },
            {
              name: "transition",
              type: "String (bounce|zoom*|slide)",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.CreateNotificationDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.createNotification("dark", "Example Content", "top-center", false, "slide")`,
        },
        {
          id: "formIsValid",
          type: "sync",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "formId",
              type: "uid",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.FormIsValidDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: "SX.formIsValid('formId')",
        },
        {
          id: "prepareDataGrid",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "selector",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
            {
              name: "columns",
              type: "List<String>",
              required: true,
            },
            {
              name: "rows",
              type: "List<String>",
              required: false,
            },
            {
              name: "summaries",
              type: "Object",
              required: false,
            },
            {
              name: "settings",
              type: "Object",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.PrepareDataGridDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.prepareDataGrid(".grid-area", ["name"], [{name:'SetXRM'}], {}, {
            width: "50%"
        })`,
        },
        {
          id: "getDxDataGridInstance",
          type: "sync",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "elementId",
              type: "uid",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.GetDxDataGridInstanceDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `var gridInstance = SX.getDxDataGridInstance("listDxable");
        gridInstance.columnOption("command:edit", "width", 1000);
        gridInstance.refresh()
        `,
        },
        {
          id: "addEventListener",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "eventName",
              type: "String",
              required: true,
            },
            {
              name: "functionBody",
              type: "Function",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.AddEventListenerDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `var createdEventName = "SXGRID_CONTENT_READY";
        var pagingEvent = "SXGRID_ON_PAGING";
    
        function x(e) {
            gridCheckbox();
            addMultipleUpdateButton();
        }
        function y(e) {
            alert()
        }
    
        SX.addEventListener(createdEventName,
            x);
        SX.addEventListener(pagingEvent,
            y)`,
        },
        {
          id: "getEventListeners",
          type: "void",
          parameterType: "",
          parameters: [],
          description: this.$t(
            "Components.ScriptHelperModal.GetEventListenersDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.getEventListeners();`,
        },
        {
          id: "removeEventListeners",
          type: "void",
          parameterType: "",
          parameters: [],
          description: this.$t(
            "Components.ScriptHelperModal.RemoveEventListenersDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.removeEventListeners();`,
        },
        {
          id: "select2ResetValue",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "select2",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.Select2ResetValueDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.select2ResetValue($(".select2"))`,
        },
        {
          id: "select2SetValue",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "select2",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
            {
              name: "ids",
              type: "ValueString|ValueString2",
              required: true,
            },
            {
              name: "texts",
              type: "TextString|TextString2",
              required: true,
            },
            {
              name: "isMultiple",
              type: "Boolean",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.Select2SetValueDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.select2SetValue($(".select2"), "1|2", "a|b", true)`,
        },
        {
          id: "prepareSelect2WithData",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "select2",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
            {
              name: "data",
              type: "Array",
              required: true,
            },
            {
              name: "isMultiple",
              type: "Boolean",
              required: false,
            },
            {
              name: "placeHolder",
              type: "String",
              required: false,
            },
            {
              name: "dropDownParentSelectorId",
              type: "WindowModalElement",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.PrepareSelect2WithDataDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.prepareSelect2WithData($(".select2"), [{id:'1', text:'a'}], false, $("#xModal"))`,
        },
        {
          id: "prepareDatePicker",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "selector",
              type: "#ElementId",
              required: true,
            },
            {
              name: "type",
              type: "String",
              required: true,
            },
            {
              name: "isNotEditable",
              type: "Boolean",
              required: false,
            },
            {
              name: "isInline",
              type: "Boolean",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.PrepareDatePickerDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.prepareDatePicker('#datetimepicker', 'datetime')`,
        },
        {
          id: "prepareSelect2",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "select2",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
            {
              name: "searchUrl",
              type: "String",
              required: true,
            },
            {
              name: "parameterObjects",
              type: "Object",
              required: false,
            },
            {
              name: "excludeId",
              type: "String",
              required: false,
            },
            {
              name: "isMultiple",
              type: "Boolean",
              required: false,
            },
            {
              name: "maximumSelectionSize",
              type: "Number",
              required: false,
            },
            {
              name: "minimumInputLength",
              type: "Number",
              required: false,
            },
            {
              name: "milliSeconds",
              type: "Number",
              required: false,
            },
            {
              name: "placeHolder",
              type: "String",
              required: false,
            },
            {
              name: "dropDownParentSelectorId",
              type: "WindowModalElement",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.PrepareSelect2Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.prepareSelect2($(".select2"), "https://prodapigateway.setxrm.com/LookupFieldValues, {})`,
        },
        {
          id: "prepareNumberInput",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "selector",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
            {
              name: "groupSeparator",
              type: "String",
              required: true,
            },
            {
              name: "radixPoint",
              type: "Boolean",
              required: true,
            },
            {
              name: "isThousandSeparator",
              type: "String",
              required: true,
            },
            {
              name: "decimalPlaces",
              type: "Number",
              required: true,
            },
            {
              name: "value",
              type: "Number",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.PrepareNumberInputDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.prepareNumber('.number-input', '.', ",", true, 3, 1111)`,
        },
        {
          id: "preparePhoneInput",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "selector",
              type: ".ElementClass Or #ElementId",
              required: true,
            },
            {
              name: "value",
              type: "String",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.PreparePhoneInputDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.preparePhoneInput('phone', '+9055431233223')`,
        },
        {
          id: "createMapView",
          type: "void",
          parameterType: "Query String Parameter",
          parameters: [
            {
              name: "selector",
              type: "ElementId",
              required: true,
            },
            {
              name: "lat",
              type: "String",
              required: true,
            },
            {
              name: "lng",
              type: "String",
              required: true,
            },
            {
              name: "settings",
              type: "Object",
              required: false,
            },
          ],
          description: this.$t(
            "Components.ScriptHelperModal.CreateMapViewDescription",
            {},
            { locale: this.$store.state.activeLang }
          ),
          example: `SX.createMapView("map-area", "49.343242", "21.54543", {zoom: 7, minZoom: 7, maxZoom: 7, zoomAnimation:true})`,
        },
      ],
    };
  },
  methods: {},
};
</script>
